var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page1" },
    [
      _c("div", { staticClass: "content bgFFF" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "Wrapper",
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.submitData.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticStyle: { width: "300px", float: "left" },
                attrs: {
                  "label-position": "right",
                  "label-width": "100px",
                  model: _vm.formInline,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Equipment_name"),
                      prop: "monitorEquipmentName",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { maxlength: 20, placeholder: "请输入内容" },
                      model: {
                        value: _vm.formInline.monitorEquipmentName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "monitorEquipmentName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.monitorEquipmentName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Equipment_type"),
                      prop: "monitorEquipmentType",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.formInline.monitorEquipmentType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "monitorEquipmentType",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.monitorEquipmentType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "HK-海康", value: 1 },
                        }),
                        _c("el-option", {
                          attrs: { label: "DH-大华", value: 2 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Belonging_parking_lot"),
                      prop: "parkId",
                    },
                  },
                  [
                    _c("auto-complete", {
                      ref: "select",
                      attrs: { slaveRelations: "0,2" },
                      on: { valueChange: _vm.completeValue },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "lnglat",
                    attrs: { label: "经纬度", prop: "lnglat" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "在地图上选择坐标点" },
                      model: {
                        value: _vm.formInline.lnglat,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "lnglat",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.lnglat",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        staticClass: "map1",
                        staticStyle: { "margin-left": "8px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.openMap },
                      },
                      [_vm._v("地图选点")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.address"),
                      prop: "address",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: 50,
                        type: "textarea",
                        placeholder: "输入车场详细地址",
                      },
                      model: {
                        value: _vm.formInline.address,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "address",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.address",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "网络模式", prop: "netType" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.formInline.netType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "netType", $$v)
                          },
                          expression: "formInline.netType",
                        },
                      },
                      [
                        _c("el-option", { attrs: { label: "固网", value: 0 } }),
                        _c("el-option", {
                          attrs: { label: "无线网络", value: 1 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formInline.netType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "IP地址", prop: "ipAddress" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: 15, placeholder: "请输入内容" },
                          model: {
                            value: _vm.formInline.ipAddress,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "ipAddress",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.ipAddress",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.netType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "端口号", prop: "ipPort" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: 10, placeholder: "请输入内容" },
                          model: {
                            value: _vm.formInline.ipPort,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "ipPort",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.ipPort",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.netType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "视频端口号", prop: "monitorPort" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: 10, placeholder: "请输入内容" },
                          model: {
                            value: _vm.formInline.monitorPort,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "monitorPort",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.monitorPort",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.netType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "用户名", prop: "account" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: 20, placeholder: "请输入内容" },
                          model: {
                            value: _vm.formInline.account,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "account",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.account",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.netType == 0
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "密码", prop: "password" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: 20, placeholder: "请输入内容" },
                          model: {
                            value: _vm.formInline.password,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.password",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.netType == 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "设备编码：", prop: "channelCode" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入设备编码",
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            maxlength: "32",
                          },
                          model: {
                            value: _vm.formInline.channelCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "channelCode", $$v)
                            },
                            expression: "formInline.channelCode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "text-align": "left", "margin-left": "8.5%" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "88px" },
                attrs: { type: "primary" },
                on: { click: _vm.submitData },
              },
              [_vm._v(_vm._s(_vm.$t("button.preservation")))]
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "88px" },
                attrs: { type: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
      _c("map-con", {
        ref: "mapConpent",
        on: { mapCancel: _vm.mapCancel, mapSure: _vm.mapSure },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 编辑监控设备 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }